<template>
  <div class="storages">
    <div class="page_header">
      <h1>Склады</h1>
      <el-button type="primary" size="mini" @click="create" icon="el-icon-plus">Добавить</el-button>
    </div>
    <div class="page_content" v-loading="loading">
      <div class="list" v-if="storages">
        <table class="table">
          <thead>
          <tr>
            <th>#</th>
            <th>Склад</th>
            <th>Адрес</th>
            <th></th>
          </tr>
          </thead>
          <tbody>
          <tr v-for="(storage, idx) in storages" :key="storage.id">
            <td>{{ ++idx }}</td>
            <td>{{ storage.title }}</td>
            <td>
              <span class="address" v-if="storage.address">{{ storage.address.value }}</span>
            </td>
            <td>
              <el-button
                  type="primary"
                  size="mini"
                  @click="openDrawer(storage)"
                  icon="el-icon-edit">
                Изменить
              </el-button>
              <el-popconfirm
                  @confirm="remove(storage.id)"
                  confirm-button-text='Да'
                  cancel-button-text='Нет'
                  title="Вы действительно хотите удалить?">
                <el-button
                    slot="reference"
                    icon="el-icon-delete"
                    size="mini" type="danger"
                    style="margin-left: 5px;"></el-button>
              </el-popconfirm>
            </td>
          </tr>
          </tbody>
        </table>
      </div>
      <div v-else>
        <p>Нет данных для отображения</p>
      </div>
    </div>
    <el-drawer
        :visible.sync="drawerActive"
        direction="rtl"
        size="30%"
        custom-class="drawer_box"
        :before-close="closeDrawer"
        :destroy-on-close="true"
        :show-close="false"
        :wrapperClosable="false"
        :withHeader="false"
        :append-to-body="true"
        class="settings_drawer"
    >
      <div class="drawer_header">
        <h3 class="title font-ui">Склад</h3>
        <button class="close_btn" @click="closeDrawer">
          <i class="el-icon-close"></i>
        </button>
      </div>

      <div class="drawer_content" v-if="formData" v-loading="submitting">
        <el-form :model="formData" :rules="formRules" ref="formData" class="form"
                 @submit.native.prevent="formDataAction('formData')">
          <el-form-item label="Название склада" prop="title">
            <el-input v-model="formData.title" size="mini"></el-input>
          </el-form-item>

          <el-form-item label="Адрес" prop="address">
            <el-autocomplete
                popper-class="ac_input_address"
                v-model="formData.address.value"
                :fetch-suggestions="addressSearch"
                placeholder="Начните вводить адрес и выберите один из вариантов"
                size="mini"
                :debounce="500"
                :trigger-on-focus="false"
                @select="handleSelectAddress"
            >
              <template slot-scope="{ item }">
                <div class="value">{{ item.value }}</div>
              </template>
            </el-autocomplete>
          </el-form-item>

          <el-form-item label="Комментарий" prop="comment">
            <el-input
                type="textarea"
                :autosize="{ minRows: 2 }"
                placeholder="Комментарий"
                v-model="formData.comment"
                size="mini"></el-input>
          </el-form-item>

          <el-form-item>
            <el-button
                type="primary"
                size="mini"
                @click="formDataAction('formData')">Сохранить
            </el-button>
            <el-button size="mini" @click="closeDrawer">Отменить</el-button>
          </el-form-item>
        </el-form>
      </div>
    </el-drawer>
  </div>
</template>

<script>

import {mapState} from 'vuex'
import {actionTypes as storageAction} from "@/store/modules/storages"
import autocompleteApi from '@/api/autocomplete'

export default {
  name: "Storages",
  data() {
    return {
      drawerActive: false,
      formData: null,
      formRules: {
        title: [
          {required: true, message: 'Введите название склада', trigger: 'blur'}
        ]
      }
    }
  },
  computed: {
    ...mapState({
      storages: state => state.storages.list,
      loading: state => state.storages.loading,
      submitting: state => state.storages.submitting,
      errors: state => state.storages.errors
    })
  },
  methods: {
    create() {
      this.formData = {
        title: "",
        comment: "",
        address: {
          value: ''
        },
      };
      this.drawerActive = true;
    },
    openDrawer(storage) {
      this.drawerActive = true
      this.formData = _.cloneDeep(storage)
    },
    closeDrawer() {
      this.formData = null
      this.drawerActive = false
    },
    formDataAction(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.$store.dispatch(storageAction.save, this.formData).then(() => {
            this.loadStorages()
            this.drawerActive = false
            this.formData = null
          })
        } else {
          console.log('error submit!!');
          return false;
        }
      });
    },
    remove(id){
      this.$store.dispatch(storageAction.remove, id).then(() => {
        this.loadStorages()
      })
    },
    loadStorages() {
      this.$store.dispatch(storageAction.get)
    },
    addressSearch(queryString, cb) {
      if (queryString.length > 4) {
        autocompleteApi.searchAddress({query: queryString}).then((result) => {
          if (result.data.length) {
            cb(result.data);
          } else {
            cb([]);
          }
        });
      } else {
        cb([]);
      }
    },
    handleSelectAddress(item) {
      this.formData.address = item
      this.formData.address.value = item.value
      console.log(item)
    },
    resetForm(formName) {
      this.$refs[formName].resetFields();
    }
  },
  created() {
    document.title = this.$route.meta.title ? this.$route.meta.title : 'GlassERP'
  }
}
</script>
